import axios from "@/helpers/useApi";
import { apiUrl } from "@/helpers/apiConfig"; // Import the apiUrl constant
import { AxiosError } from "axios";
import { router } from "@/router/router";

export function useDevelopmentPlotApi() {
  const getDevelopmentsRequest = async () => {
    try {
      return await axios.get(`${apiUrl}/api/developments`);
    } catch (error) {
      throw handleError(error);
    }
  };

  const getPlotsRequest = async (developmentId: string) => {
    try {
      return await axios.get(
        `${apiUrl}/api/developments/${developmentId}/plots`,
      );
    } catch (error) {
      throw handleError(error);
    }
  };

  const handleError = (error: any) => {
    if (error instanceof AxiosError && error.response) {
      if (error.response.status === 404) {
        router.push({ name: "404" });
        return "404 - not found;";
      }

      return error.response.data;
    }

    return error;
  };

  return {
    getDevelopmentsRequest,
    getPlotsRequest,
  };
}
