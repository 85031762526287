import axios from "@/helpers/useApi";
import { apiUrl } from "@/helpers/apiConfig"; // Import the apiUrl constant
import { AxiosError } from "axios";
import { router } from "@/router/router";
import { FormAnswersType } from "@/types/FormTypes";

export function useFormsApi() {
  const getFormsRequest = async (plotId: string, params: object) => {
    const queryParams = {
      page: 1,
      ...params,
    };

    try {
      return await axios.get(`${apiUrl}/api/plots/${plotId}/forms`, {
        params: queryParams,
      });
    } catch (error) {
      throw handleError(error);
    }
  };

  const getFormRequest = async (id: string, plotId: string) => {
    try {
      return await axios.get(`${apiUrl}/api/plots/${plotId}/forms/${id}`);
    } catch (error) {
      throw handleError(error);
    }
  };

  const saveFormRequest = async (
    id: string,
    plotId: string,
    formData: FormAnswersType,
  ) => {
    try {
      return await axios.post(
        `${apiUrl}/api/plots/${plotId}/forms/${id}/save`,
        formData,
        {},
      );
    } catch (error) {
      throw handleError(error);
    }
  };

  const submitFormRequest = async (
    id: string,
    plotId: string,
    formData: FormAnswersType,
  ) => {
    try {
      return await axios.post(
        `${apiUrl}/api/plots/${plotId}/forms/${id}/submit`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
    } catch (error) {
      throw handleError(error);
    }
  };

  const handleError = (error: any) => {
    if (error instanceof AxiosError && error.response) {
      if (error.response.status === 404) {
        router.push({ name: "404" });
        return "404 - not found;";
      }

      return error.response.data;
    }

    return error;
  };

  return {
    getFormsRequest,
    getFormRequest,
    saveFormRequest,
    submitFormRequest,
  };
}
