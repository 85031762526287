export const TOAST_SUCCESS_TIME = 5000;
export const TOAST_ERROR_TIME = 25000;

export const TOAST_TIME = (type: "success" | "error" = "success") => {
  if (type === "error") {
    return TOAST_SUCCESS_TIME + TOAST_ERROR_TIME;
  }

  return TOAST_SUCCESS_TIME;
};
