import axios from "@/helpers/useApi";
import { apiUrl } from "@/helpers/apiConfig"; // Import the apiUrl constant

export function useUserApi() {
  const getProfileRequest = async () => {
    try {
      return await axios.get(`${apiUrl}/api/profile`);
    } catch (error: any) {
      console.error("Error getting profile:", error);

      throw error.response.data.message;
    }
  };

  return {
    getProfileRequest,
  };
}
