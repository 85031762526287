<script lang="ts">
export default {
  emits: ["opened", "closed", "completed"],

  methods: {
    onEnter(element: any) {
      element.style.height = `auto`;

      const { height } = getComputedStyle(element);

      element.style.height = "0";

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },

    onAfterEnter(element: any) {
      element.style.height = `auto`;

      this.$emit("opened");
      this.$emit("completed");
    },

    onLeave(element: any) {
      const { height } = getComputedStyle(element);

      element.style.height = height;

      requestAnimationFrame(() => {
        element.style.height = "0";
      });
    },

    onAfterLeave() {
      this.$emit("closed");
      this.$emit("completed");
    },
  },
};
</script>

<template>
  <Transition
    @after-enter="onAfterEnter"
    @enter="onEnter"
    @leave="onLeave"
    @after-leave="onAfterLeave"
    enter-active-class="!overflow-hidden"
    leave-active-class="!overflow-hidden"
  >
    <slot></slot>
  </Transition>
</template>
