<script setup lang="ts">
const props = defineProps({
  cell: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <div class="p-1 leading-none">
    <template v-if="props.cell.component">
      <component
        :is="props.cell.component"
        v-bind="props.cell.props"
      ></component>
    </template>

    <template v-else>
      <div v-html="props.cell.content"></div>
    </template>
  </div>
</template>
