import { defineStore } from "pinia";
import { useDevelopmentPlotApi } from "@/helpers/useDevelopmentPlotApi";
import { DevelopmentDataType } from "@/types/DevelopmentTypes";
import { PlotDataType } from "@/types/PlotTypes";
import {
  ApiFetchDevelopmentsResponse,
  ApiFetchPlotsResponse,
} from "@/types/ApiTypes";

const { getDevelopmentsRequest, getPlotsRequest } = useDevelopmentPlotApi();

export let useAppStore = defineStore("app", {
  state: (): AppState => ({
    isLoading: false,
    showBackLink: false,
    scrolled: false,
    scroll: {
      y: 0,
      bottomArrived: false,
    },

    development: null,
    developments: null,
    plot: null,
    plots: null,

    title: {
      main: "",
    },
  }),

  persist: true,

  actions: {
    setTitle(title: string) {
      this.title.main = title;
    },

    clearTitle() {
      this.title.main = "";
    },

    setLoading(loading: boolean) {
      this.isLoading = loading;
    },

    setScrolled(scrolled: boolean) {
      this.scrolled = scrolled;
    },

    setPlot(plot: PlotDataType | null) {
      this.plot = plot;
    },

    setDevelopment(development: DevelopmentDataType | null) {
      this.development = development;
    },

    async getDevelopments() {
      this.setLoading(true);

      try {
        const developments = await getDevelopmentsRequest();

        this.developments = developments.data;
      } catch (error: any) {
        console.error("Error getting developments: ", error);
      } finally {
        this.setLoading(false);
      }
    },

    async getPlots(developmentId: string) {
      this.setLoading(true);

      try {
        const plots = await getPlotsRequest(developmentId);

        this.plots = plots.data;
      } catch (error: any) {
        console.error("Error getting plots: ", error);
      } finally {
        this.setLoading(false);
      }
    },
  },

  getters: {
    getScrollPositionPercent: (state) => {
      // Pixel amount to scroll before the final state of 1 is reached
      const PIXEL_AMOUNT = 100;
      let percentage = 0;

      if (state.scroll.y > PIXEL_AMOUNT) return 1;
      if (state.scroll.bottomArrived) return 1;

      percentage = state.scroll.y / PIXEL_AMOUNT;

      return percentage.toFixed(2);
    },
  },
});

export interface AppState {
  showBackLink: boolean;
  isLoading: boolean;

  development: null | DevelopmentDataType;
  developments: null | ApiFetchDevelopmentsResponse;
  plot: null | PlotDataType;
  plots: null | ApiFetchPlotsResponse;

  title: {
    main: string;
  };

  scrolled: boolean;
  scroll: {
    y: number;
    bottomArrived?: boolean;
  };
}
