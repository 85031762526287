<script lang="ts" setup>
import iconLibrary from "@/assets/svgLibrary";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: "",
  },
  class: {
    type: String,
    default: "",
  },
});
</script>

<template>
  <span>
    <span class="sr-only">{{ props.alt }}</span>

    <component :is="iconLibrary[props.name]" :class="props.class" />
  </span>
</template>
