<script setup lang="ts">
import { ref } from "vue";
import { router } from "@/router/router";
import { vScroll } from "@vueuse/components";
import { TOAST_TIME } from "@/helpers/appConfig";

import { useAppStore } from "@/stores/appStore";
import { useFormStore } from "@/stores/formStore";
import { useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";

import Header from "@/components/Header.vue";
import Loader from "@/components/shared/Loader.vue";
import Toast from "primevue/toast";

import type { UseScrollReturn } from "@vueuse/core";

const appStore = useAppStore();
const mainEl = ref<HTMLElement | null>(null);
const route = useRoute();
const formStore = useFormStore();
const toast = useToast();

const resetScroll = () => {
  if (mainEl.value) {
    mainEl.value.scrollTop = 0;
  }
};

router.afterEach(() => {
  resetScroll();
});

function onScroll(state: UseScrollReturn) {
  if (state.y.value > 100) return;
  if (state.arrivedState.bottom) return;

  appStore.scroll.y = state.y.value;
}

const handleFormSave = () => {
  formStore
    .saveForm(route.params.formId as string, route.params.plotId as string)
    .then(() => {
      toast.add({
        severity: "success",
        summary: "Form saved",
        detail: "The form has been saved successfully",
        life: TOAST_TIME(),
      });
    })
    .catch((error) => {
      toast.add({
        severity: "error",
        summary: "Form not saved",
        detail: "There was an error while saving the form",
        life: TOAST_TIME("error"),
      });
    })
    .finally(() => {
      appStore.setLoading(false);
    });
};

const handleFormSubmit = () => {
  formStore
    .submitForm(route.params.formId as string, route.params.plotId as string)
    .then(() => {
      toast.add({
        severity: "success",
        summary: "Form submitted",
        detail: "The form has been submitted successfully",
        life: TOAST_TIME(),
      });

      router.push({
        name: "purchaser-forms",
        params: { plotId: route.params.plotId as string },
      });
    })
    .catch(() => {
      toast.add({
        severity: "error",
        summary: "Error submitting form",
        detail:
          "There was an error while submitting the form. Please try again.",
        life: TOAST_TIME("error"),
      });
    });
};
</script>

<template>
  <div
    class="grid h-[100dvh] max-h-[100dvh] w-[100vw] max-w-[100vw] grid-rows-layout grid-areas-layout"
  >
    <Header @saveForm="handleFormSave" @submitForm="handleFormSubmit" />

    <main
      class="overflow-y-auto overflow-x-hidden grid-in-main overscroll-none relative"
      ref="mainEl"
      v-scroll="[onScroll, { behavior: 'smooth' }]"
    >
      <Toast
        position="bottom-right"
        contentStyleClass="flex gap-2"
        class="ml-[20px] mb-[80px] lg:mb-0"
      >
        <template #container="{ message, closeCallback }">
          <section
            class="flex items-start"
            :class="{
              'bg-red text-white': message.severity === 'error',
              'bg-warning dark:text-primary': message.severity === 'warn',
              'bg-green dark:text-primary': message.severity === 'success',
            }"
          >
            <div class="flex flex-col w-full p-4">
              <p class="m-0 font-semibold">{{ message.summary }}</p>
              <p class="m-0 text-base text-700">
                {{ message.detail }}
              </p>
            </div>

            <button type="button" class="p-4" @click="closeCallback">
              &times;
            </button>
          </section>
        </template>
      </Toast>

      <div class="absolute z-10 inset-0 pointer-events-none">
        <Loader :isLoading="appStore.isLoading" class="absolute inset-0" />
      </div>

      <RouterView v-slot="{ Component }">
        <transition name="slide" mode="out-in">
          <component
            :is="Component"
            :key="$route.path"
            @saveForm="handleFormSave"
            @submitForm="handleFormSubmit"
          />
        </transition>
      </RouterView>
    </main>
  </div>
</template>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.1s;
  overflow: hidden;
  max-width: 100vw;
  opacity: 1;
}

.slide-leave-to {
  opacity: 0;
  transform: translateX(-25%);
}

.slide-enter-from {
  opacity: 0;
  transform: translateX(25%);
}
</style>
