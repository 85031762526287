<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: "",
  },

  content: {
    type: String,
    default: "",
  },
});
</script>

<template>
  <section
    class="section__rich-text-block my-6 border-t border-black py-6 dark:border-grey/50"
  >
    <h2 v-if="props.title">
      {{ props.title }}
    </h2>

    <div class="rich-text" v-if="true">
      <slot></slot>
    </div>
  </section>
</template>

<style>
.rich-text {
  a {
    @apply font-bold underline;

    &:hover,
    &:active {
      @apply text-secondary;
    }
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  ul,
  ol {
    @apply my-8 ml-4;

    li {
      @apply mb-6;
    }
  }

  p {
    @apply mb-6;

    &:last-of-type {
      @apply mb-0;
    }
  }
}
</style>
