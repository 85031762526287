<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: "",
  },

  subTitle: {
    type: String,
    default: "",
  },

  class: {
    type: String,
    default: "",
  },
});
</script>

<template>
  <header>
    <div class="no-container">
      <slot></slot>
    </div>

    <div class="my-4 lg:my-8" :class="props.class">
      <h1 class="mt-4 lg:mt-8" v-if="props.title">
        {{ props.title }}
      </h1>

      <h2 v-if="props.subTitle">
        {{ props.subTitle }}
      </h2>
    </div>
  </header>
</template>
