import axios from "@/helpers/useApi";
import { apiUrl, DEVICE_NAME } from "@/helpers/apiConfig"; // Import the apiUrl constant

export function useAuthApi() {
  const loginUserRequest = async (credentials: {
    email: string;
    password: string;
    device_name?: string;
  }) => {
    if (!credentials.device_name) {
      credentials.device_name = DEVICE_NAME;
    }

    try {
      return await axios.post(`${apiUrl}/api/login`, credentials, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error: any) {
      console.error("Error logging in:", error);

      throw error.response.data.message;
    }
  };

  const forgotPasswordRequest = async (email: string) => {
    try {
      return await axios.post(`${apiUrl}/api/forgot-password`, { email });
    } catch (error: any) {
      console.error("Error sending forgot password email:", error);

      throw error.response.data.message;
    }
  };

  const refreshLoginTokenRequest = async (deviceName?: string) => {
    try {
      const headers = {
        headers: {
          Accept: "application/json",
        },
      };

      const body = {
        device_name: DEVICE_NAME,
      };

      return await axios.post(`${apiUrl}/api/refresh`, body, headers);
    } catch (error: any) {
      console.error("Error refreshing login session:", error);

      throw error.response.data.message;
    }
  };

  const resetPasswordRequest = async (
    token: string,
    email: string,
    password: string,
    password_confirmation: string,
  ) => {
    try {
      return await axios.post(`${apiUrl}/api/reset-password`, {
        token,
        email,
        password,
        password_confirmation,
      });
    } catch (error: any) {
      console.error("Error resetting password:", error);

      throw error.response.data.message;
    }
  };

  const logoutRequest = async () => {
    try {
      const headers = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const body = {
        device_name: DEVICE_NAME,
      };

      return await axios.post(`${apiUrl}/api/logout`, body, headers);
    } catch (error: any) {
      console.error("Error logging out:", error);

      throw error.response.data.message;
    }
  };

  return {
    loginUserRequest,
    refreshLoginTokenRequest,
    forgotPasswordRequest,
    resetPasswordRequest,
    logoutRequest,
  };
}
