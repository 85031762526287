<script setup lang="ts">
import ChecklistItemVue from "@/components/pre-api-page/ChecklistItem.vue";

const props = defineProps({
  items: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <div class="-mx-4 lg:container lg:mx-0" v-if="props.items.rows.length > 0">
    <div
      class="checklist__table grid grid-cols-[1fr_auto_auto_auto] items-center gap-2 lg:grid-cols-[1fr_auto_auto_70px]"
    >
      <div
        class="caption col-span-full grid grid-cols-subgrid gap-2 bg-white px-4 uppercase dark:text-black"
      >
        <div class="px-1 py-[6px]" v-for="header in props.items.header">
          {{ header.label }}
        </div>
      </div>

      <div
        class="col-span-full mx-4 grid grid-cols-subgrid items-center gap-2 border-b border-white"
        v-for="row in props.items.rows"
      >
        <ChecklistItemVue v-for="cell in row.cells" :cell="cell" />
      </div>
    </div>
  </div>
</template>

<style>
.caption {
  @apply text-caption font-caption leading-caption;
}

i.dot {
  @apply inline-block h-[12px] w-[12px] rounded-full bg-black dark:bg-white;
}
</style>
