import { defineStore } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { v4 as uuid } from "uuid";

import { useFormsApi } from "@/helpers/useFormsApi";
import { useAppStore } from "@/stores/appStore";
import { ApiFetchFormResponse, ApiFetchFormsResponse } from "@/types/ApiTypes";
import {
  FormAnswerIssueImageIssueType,
  FormAnswerIssueImageType,
  FormAnswerType,
} from "@/types/FormAnswerTypes";
import { FieldPhotoType } from "@/types/FormQuestionTypes";
import { deepMerge } from "@/helpers/helpers";

dayjs.extend(utc);

const { getFormsRequest, getFormRequest, saveFormRequest, submitFormRequest } =
  useFormsApi();

export let useFormStore = defineStore("forms", {
  state: (): FormState => ({
    forms: {
      data: [],
      links: {
        first: null,
        last: null,
        prev: null,
        next: null,
      },
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [],
        path: "",
        per_page: 1,
        to: 1,
        total: 1,
      },
    },
    form: {
      data: {
        id: 0,
        title: "",
        unlocked_at: null,
        expiration_date: null,
        updated_at: null,
        created_at: "",
        completed_at: null,
        signature: {
          type: "signature",
          created_at: "",
          updated_at: null,
          order: 1,
          title: null,
          subtitle: null,
          settings: {
            header_id: 1,
            required: true,
          },
        },
        terms_conditions: null,
        questions: [],
        answers: {
          id: 0,
          signature: null,
          created_at: null,
          updated_at: null,
          answers: [],
        },
      },
    },
  }),

  persist: true,

  actions: {
    async fetchForms(plotId: string, params: object = {}) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        const forms = await getFormsRequest(plotId, params);

        this.forms = forms.data;
      } catch (error: any) {
        console.error("Error getting forms: ", error);
        throw new Error("Error getting forms: " + error);
      } finally {
        appStore.setLoading(false);
      }
    },

    async fetchForm(id: string, plotId: string) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        const form = await getFormRequest(id, plotId);

        if (form.status === 200) {
          this.form = form.data;
        } else {
          console.error("Error getting form: ", form);
        }
      } catch (error: any) {
        this.clearSelectedForm();
        throw new Error("Error getting form: " + error);
      } finally {
        appStore.setLoading(false);
      }
    },

    async saveForm(id: string, plotId: string) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        const save = await saveFormRequest(
          id,
          plotId,
          this.createFormSubmitObject(),
        );

        if (save.status === 200) {
          return save;
        } else {
          console.error("Error saving form: ", save);
          throw new Error("Error saving form: " + save);
        }
      } catch (error: any) {
        console.error("Catch Error saving form: ", error);
        throw new Error("Error saving form: " + error);
      } finally {
        appStore.setLoading(false);
      }
    },

    async submitForm(id: string, plotId: string) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        const submitForm = await submitFormRequest(
          id,
          plotId,
          this.createFormSubmitObject(),
        );

        if (submitForm.status === 200) {
          return submitForm;
        } else {
          console.error("Error submitting form: ", submitForm);
          throw new Error("Error submitting form: " + submitForm);
        }
      } catch (error: any) {
        console.error("Error submitting form: ", error);
        throw new Error("Error submitting form: " + error);
      } finally {
        appStore.setLoading(false);
      }
    },

    createFormSubmitObject() {
      return JSON.parse(
        JSON.stringify({
          answers: this.form.data.answers.answers,
        }),
      );
    },

    createNewAnswer(questionId: number): FormAnswerType {
      return {
        uuid: uuid(),
        question_id: questionId,
        created_at: this.newDateStamp(),
        updated_at: this.newDateStamp(),
      };
    },

    findOrCreateAnswer(questionId: number): FormAnswerType {
      const answer = this.form.data.answers.answers.find(
        (answer) => answer.question_id === questionId,
      );

      if (answer) {
        return answer;
      }

      this.form.data.answers.answers.push(this.createNewAnswer(questionId));

      return this.form.data.answers.answers[
        this.form.data.answers.answers.length - 1
      ];
    },

    addOrUpdateAnswer(answer: FormAnswerType) {
      const index = this.form.data.answers.answers.findIndex(
        (a) => a.question_id === answer.question_id,
      );

      let newAnswer = this.findOrCreateAnswer(
        parseInt(answer.question_id as any),
      );

      newAnswer = {
        ...newAnswer,
        ...answer,
      };

      if (index > -1) {
        this.form.data.answers.answers[index] = newAnswer;
      } else {
        this.form.data.answers.answers.push(newAnswer);
      }
    },

    clearSelectedForm() {
      this.form = {
        data: {
          id: 0,
          title: "",
          unlocked_at: null,
          expiration_date: null,
          updated_at: null,
          created_at: "",
          completed_at: null,
          signature: {
            type: "signature",
            created_at: "",
            updated_at: null,
            order: 1,
            title: null,
            subtitle: null,
            settings: {
              header_id: 1,
              required: true,
            },
          },
          terms_conditions: null,
          questions: [],
          answers: {
            id: 0,
            signature: null,
            created_at: null,
            updated_at: null,
            answers: [],
          },
        },
      };
    },

    addOrUpdateIssueItem(
      questionId: string,
      uuid: string,
      currentIssue: FormAnswerIssueImageIssueType,
    ) {
      const answer = this.findOrCreateAnswer(
        parseInt(questionId),
      ) as FormAnswerIssueImageType;

      if (!answer?.answer_issues) {
        answer.answer_issues = [];
      }

      const index = answer.answer_issues.findIndex(
        (issue) => issue.uuid === uuid,
      );

      if (index === -1) {
        answer.answer_issues.push(currentIssue);
      } else {
        answer.answer_issues.splice(index, 1, currentIssue);
      }
    },

    findIssueItemAnswer(questionId: string): FormAnswerIssueImageType {
      const foundAnswer = this.form?.data?.answers?.answers?.find(
        (answer) => answer.question_id === parseInt(questionId),
      ) as FormAnswerIssueImageType;

      return foundAnswer;
    },

    getOrCreateIssueItem(
      questionId: string,
      uuid: string,
    ): FormAnswerIssueImageIssueType {
      const answer = this.findIssueItemAnswer(questionId);

      const foundIssue = answer?.answer_issues?.find(
        (issue) => issue.uuid === uuid,
      ) as FormAnswerIssueImageIssueType;

      if (foundIssue) {
        return foundIssue;
      }

      return {
        uuid,
        updated_at: this.newDateStamp(),
        deleted_at: null,
        title: "",
        comment: "",
        answer_media_ids: [],
      };
    },

    createNewPhotoQuestion(settings: object = {}): FieldPhotoType {
      return deepMerge(
        {
          type: "photo",
          id: 491,
          order: 1,
          title: "Photos",
          subtitle: "",
          created_at: this.newDateStamp(),
          updated_at: this.newDateStamp(),
          settings: {
            required: true,
            min_files: 1,
            max_files: 10,
            file_types: ["image/jpeg", "image/png"],
            max_file_size: 8192,
          },
          answer: {
            uuid: uuid(),
            question_id: 1,
            created_at: this.newDateStamp(),
            updated_at: this.newDateStamp(),
            answer_media_ids: [],
          },
        },
        settings,
      );
    },

    newDateStamp() {
      return dayjs().utc().format("YYYY-MM-DD HH:mm:ss");
    },
  },
});

interface FormState {
  forms: ApiFetchFormsResponse;
  form: ApiFetchFormResponse;
}
