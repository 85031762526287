<script setup lang="ts">
import { ref, PropType } from "vue";
import { AccordionItemType } from "@/components/pre-api-page/AccordionItem.vue";

const props = defineProps({
  allowMultiple: {
    type: Boolean,
    required: false,
    default: false,
  },

  items: {
    type: Array as PropType<AccordionItemType[]>,
    required: true,
  },
});

const accordions = ref([]);

const handleOpen = (id: number) => {
  if (!props.allowMultiple) {
    accordions.value.forEach((accordion: any) => {
      if (accordion.id !== id) {
        accordion.close();
      }
    });
  }
};
</script>

<template>
  <div class="accordion__group">
    <AccordionItem
      ref="accordions"
      v-for="item in props.items"
      :key="item.id"
      :disabled="item.disabled"
      :open="item.open"
      @open="handleOpen"
    >
      <template #title>
        <div v-html="item.title"></div>
      </template>

      <template v-if="item.components">
        <component
          v-for="theComponent in item.components"
          :is="theComponent.component"
          v-bind="theComponent.props"
        >
          <div v-html="theComponent.props.content ?? ''"></div>
        </component>
      </template>

      <template v-if="item.component">
        <component :is="item.component" v-bind="item.props">
          <div v-html="item.props?.content ?? ''"></div>
        </component>
      </template>

      <template v-else>
        <div v-html="item.content"></div>
      </template>
    </AccordionItem>
  </div>
</template>
